<template>
  <b-card :title="$t('work')">
    <b-row>
      <b-col md="4" class="mb-2">
        <p v-if="currentProcess">Вибраний процес роботи: <b-badge variant="primary">{{ currentProcess.text }}</b-badge></p>
        <b-input-group class="mt-3">
          <b-button-group>
            <b-button size="sm" variant="outline-secondary" title="Скинути фільтр">
              <fa icon="filter" />
            </b-button>
            <b-button
              size="sm"
              variant="outline-dark"
            >{{ hasOneRowOpen ? 'Згорнути' : 'Розгорнути'}}</b-button>
          </b-button-group>
          <!--<b-form-datepicker
            id="shift-datepicker"
            v-model="shiftData"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            locale="ru"
            @input="changeFilterDate"
          ></b-form-datepicker>-->
          <select class="form-control" @change="changeFilterDate">
            <option v-for="(item, index) in shiftDates" :key="index" :value="item.id" :selected="currentShiftDate === item">{{ item.created_at }}</option>
          </select>
          <b-form-select v-model="selectedProcess" :options="processOptions" @change="changeProcess"></b-form-select>
        </b-input-group>
      </b-col>
      <b-col md="5">
        <b-row>
          <b-col mb="2"><label class="mr-2">{{ $t('count_p_in_parties') }}:</label></b-col>
          <b-col mb="10">
            <b-form-input
            label="defult value"
            type="number"
            size="sm"
            @change="changePartiesValue($event)"
            v-model.number="partyValueUp"
          ></b-form-input>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="3" class="text-right">
          <!--<b-button
          v-if="!shift"
          size="sm"
          variant="danger"
          @click="openShift">{{ $t('open_shift') }}</b-button>-->
      </b-col>
    </b-row>
    <b-table
      responsive
      show-empty
      sort-icon-left
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="0"
      v-model="currentItems"
      ref="tableTasks"
    >
      <template v-slot:head(selected)>
        <div class="custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" id="check-tasks" @click="selectAll" />
          <label class="custom-control-label" for="check-tasks"></label>
        </div>
      </template>
      <template v-slot:cell(selected)="row">
        <b-button-group>
          <!--<div class="custom-control custom-checkbox">
              <input
              type="checkbox"
              class="custom-control-input"
              :id="`checkbox-${row.item.id}`"
              :name="`checkbox-${row.item.id}`"
              :value="row.item.id"
              v-model="selectIds"
              @change="selectAllStages">
              <label class="custom-control-label" :for="`checkbox-${row.item.id}`"></label>
          </div>-->
          <b-button size="sm" @click="row.toggleDetails" class="mr-2" variant="outline-info" pill>
            <fa :icon="row.detailsShowing ? 'minus' : 'plus'" />
          </b-button>
        </b-button-group>
      </template>
      <template v-slot:cell(order_status)="row">
        <b-badge
          v-if="row.item.order_status_name === 'Новий'"
          variant="primary"
        >{{ row.item.order_status_name }}</b-badge>
        <b-badge
          v-else-if="row.item.order_status_name === 'В роботі'"
          variant="warning"
        >{{ row.item.order_status_name }}</b-badge>
        <b-badge
          v-else-if="row.item.order_status_name === 'Відміна'"
          variant="danger"
        >{{ row.item.order_status_name }}</b-badge>
        <b-badge
          v-else-if="row.item.order_status_name === 'Готово'"
          variant="success"
        >{{ row.item.order_status_name }}</b-badge>
        <b-badge v-else>{{ row.item.order_status_name }}</b-badge>
      </template>
      <!-- subtable -->
      <template v-slot:row-details="row">
        <table class="table table-sm table-light">
          <tbody>
            <tr v-for="(item, idxs) in row.item.products" :key="idxs">
              <td>
                <p>
                  Група:
                  <strong>{{ item.group }}</strong>
                  <br />Товар:
                  <strong>{{ item.product }}</strong>
                </p>
                <div>
                    <strong>{{ $t('product_status') }}: </strong>
                    <b-badge v-if="item.status === 'Готово'" variant="success">{{ $t('done') }}</b-badge>
                    <b-badge v-else-if="item.status === 'Новий'" variant="primary">{{ $t('new') }}</b-badge>
                    <b-badge v-else-if="item.status === 'В роботі'" variant="warning">{{ $t('in_work') }}</b-badge>
                    <b-badge v-else-if="item.status === 'Не укомплектовано'" variant="danger">{{ item.status }}</b-badge>
                    <b-badge v-else variant="secondary">{{ item.status }}</b-badge>
                  </div>
                <p>
                  {{ $t('quantity') }}:
                  <b-badge>{{ item.quantity }}</b-badge>
                </p>
                <strong>Етапи:</strong>
                <table class="table table-borderles">
                  <thead>
                    <th></th>
                    <th>#</th>
                    <th>{{ $t('stage') }}</th>
                    <th>{{ $t('characteristic') }}</th>
                    <th class="text-center">{{ $t('ready') }}</th>
                    <th>{{ $t('status') }}</th>
                    <th>{{ $t('photo') }}</th>
                    <th>{{ $t('photo_layout') }}</th>
                    <th>{{ $t('comment_to_stage') }}</th>
                  </thead>
                  <tr v-for="(stage, idx) in item.stages" :key="idx">
                    <td>
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          :id="`check-tasks-stage-${stage.id}`"
                          :value="stage.id"
                          v-model="selectIdStages"
                          @change="selectStage($event, stage.id, item.id)"
                        />
                        <label class="custom-control-label" :for="`check-tasks-stage-${stage.id}`"></label>
                      </div>
                    </td>
                    <td>{{ stage.number }}</td>
                    <td class="text-center">
                      <p>{{ stage.stage }}</p>
                      <b-button-group vertical>
                        <b-button
                          variant="outline-danger"
                          size="sm"
                          @click="removeStage(stage.wid)"
                        >{{ $t('remove') }}</b-button>
                      </b-button-group>
                    </td>
                    <td>{{ stage.characteristic }}</td>
                    <td class="text-center">
                      {{ stage.ready }}
                        <b-form-input size="sm"
                        type="number"
                        :max="item.quantity"
                        min="0"
                        v-model="stage.value"></b-form-input>
                        <b-button-group vertical class="mt-1">
                          <b-button
                            v-if="stage.status !== 'Готово'"
                            variant="outline-success"
                            size="sm"
                            @click="doneStage(row.item.id, item.id, stage.id, stage.wid, 0)"
                          >{{ $t('done') }}</b-button>
                          <b-button
                            v-if="stage.ready_qty > 0"
                            variant="outline-danger"
                            size="sm"
                            @click="cancelStage(row.item.id, item.id, stage.id, stage.wid)"
                          >{{ $t('cancel') }}</b-button>
                          <b-button
                            v-if="stage.ready_qty !== item.quantity"
                            variant="outline-info"
                            size="sm"
                            @click="doneStage(row.item.id, item.id, stage.id, stage.wid, 1)"
                          >{{ $t('close_party') }}</b-button>
                        </b-button-group>
                    </td>
                    <td>
                      <b-badge v-if="stage.status === 'Готово'" variant="success">{{ $t('done') }}</b-badge>
                      <b-badge
                        v-else-if="stage.status === 'Новий'"
                        variant="primary"
                      >{{ $t('new') }}</b-badge>
                      <b-badge
                        v-else-if="stage.status === 'Відміна'"
                        variant="danger"
                      >{{ $t('cancel') }}</b-badge>
                      <b-badge v-else variant="warning">{{ stage.status }}</b-badge>
                    </td>
                    <td>
                      <b-img-lazy :src="apiUrl + stage.photo" fluid width="150" thumbnail />
                    </td>
                    <td>
                      <b-img-lazy :src="apiUrl + stage.photo_layout" fluid width="150" thumbnail />
                    </td>
                    <td>
                      <p>{{ stage.comment }}</p>
                      <b-form-group>
                        <b-form-textarea size="sm" v-model="stage.newComment"></b-form-textarea>
                      </b-form-group>
                      <b-button size="sm" @click="addComment(row.item.id, item.id, stage.id)">{{ $t('add') }}</b-button>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </template>
    </b-table>
    <!-- Pagination -->
    <b-pagination size="sm" :total-rows="totalItems" v-model="currentPage" :per-page="perPage"/>
  </b-card>
</template>
<script>
import axios from 'axios'
import Swal from 'sweetalert2'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Cookies from 'js-cookie'
export default {
  middleware: 'auth',
  layout: 'default',
  metaInfo () {
    return { title: this.$t('work') }
  },
  data () {
    return {
      items: [],
      fields: [
        {
          key: 'selected',
          label: '',
          sortable: false
        },
        {
          key: 'order_num',
          label: '# замовлення',
          typesort: 'text'
        },
        {
          key: 'created_at',
          label: 'Дата',
          typesort: 'doubleinput'
        },
        {
          key: 'quantity',
          label: 'К-сть'
        },
        {
          key: 'order_status',
          label: 'Статус зам.',
          typesort: 'select'
        },
        {
          key: 'ready',
          label: 'Готовність',
          class: 'text-center'
        },
        {
          key: 'comment',
          label: 'Коментар',
          class: 'text-center'
        }
      ],
      currentItems: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      totalItems: 0,
      urlParams: {},
      selected: [],
      selectIds: [],
      selectIdStages: [],
      selectProductStages: [],
      apiUrl: process.env.VUE_APP_ENDPOINT_URL,
      shiftData: '',
      shiftDates: [],
      processOptions: [],
      selectedProcess: Cookies.get('processId')
    }
  },
  mounted () {
    if (this.processId) {
      this.urlParams.process_id = this.processId
    }
    setTimeout(() => {
      if (this.shift && this.shift.id) {
        console.log(this.shift)
        this.urlParams.shift_date = this.shift.id
      }
    }, 1000)
    if (this.partiesValue === 0) {
      this.fetchPartiesValue()
    }
    if (this.processOptions.length === 0) {
      this.fetchWorkingProcess()
    }
    if (this.shiftDates.length === 0) {
      this.getShiftDates()
    }
    /*
    this.fetchData().catch((error) => {
      console.log(error)
    })
    */
  },
  computed: {
    ...mapGetters({
      shift: 'shift/shift',
      partiesValue: 'shift/partiesValue',
      processId: 'shift/processId'
    }),
    currentShiftDate () {
      if (this.shift && this.shift.created_at) {
        return this.shift.created_at
      } else {
        return this.shiftDates[0].id
      }
    },
    currentProcess () {
      if (this.processOptions) {
        const process = this.processOptions.find(p => p.value === this.processId)
        return process
      }
      return null
    },
    // eslint-disable-next-line vue/return-in-computed-property
    hasOneRowOpen () {
      for (const item of this.items) {
        if (item._showDetails) {
          return true
        }
      }
    },
    partyValueUp: {
      get () {
        return Number.parseInt(this.partiesValue)
      },
      set (newValue) {
        return Number.parseInt(newValue)
      }
    }
  },
  methods: {
    ...mapActions({
      fetchPartiesValue: 'shift/fetchPartiesValue',
      setProcessId: 'shift/setProcessId'
    }),
    ...mapMutations({
      setPartiesValue: 'shift/SET_PARTIES_VALUE'
    }),
    async addComment (orderId, productId, stageId) {
      const order = this.items.find(i => i.id === orderId)
      if (order) {
        const product = order.products.find(p => p.id === productId)
        if (typeof product !== 'undefined') {
          const stage = product.stages.find(s => s.id === stageId)
          if (stage && stage.newComment) {
            this.$root.$refs.loading.start()
            const apiUrl = process.env.VUE_APP_ENDPOINT_V1
            await axios.post(apiUrl + 'orders/comment/' + stageId, { comment: stage.newComment })
            this.$root.$refs.loading.finish()
            this.fetchData()
          }
        }
      }
    },
    async changePartiesValue (val) {
      const value = Number.parseInt(val)
      if (value > 0) {
        this.$root.$refs.loading.start()
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        const { data } = await axios.post(apiUrl + 'worker/config', {
          partyValue: value
        })
        this.setPartiesValue({ partiesValue: data })
        this.$root.$refs.loading.finish()
      }
    },
    /*
    async closeParty (pid) {
      if (this.shift) {
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        // parties/done/{id}
        // await axios.post(apiUrl + 'parties/done/' + pid)
      } else {
        Swal.fire({
          title: 'Помилка!',
          text: 'Для того щоб закрити партію, вам потрібно відкрити зміну!',
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      }
    },
    */
    async getShiftDates () {
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.post(apiUrl + 'shift/dates').then(resp => {
        this.shiftDates = resp.data
        this.urlParams.shift_date = this.shiftDates[0].id

        this.fetchData()
      })
    },
    async removeStage (wid) {
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.post(apiUrl + 'production/removestage/' + wid)
      this.fetchData()
    },
    async fetchWorkingProcess () {
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.post(apiUrl + 'working/process').then(resp => {
        if (resp.data) {
          this.processOptions = resp.data
          // this.processOptions.push({ value: null, text: 'Виберіть виробничий процес' })
        }
      })
    },
    async cancelStage (orderId, productId, stageId, jobId) {
      if (this.shift) {
        const order = this.items.find(o => o.id === orderId)
        const product = order.products.find(p => p.id === productId)
        const stage = product.stages.find(s => s.id === stageId)
        const val = stage.value
        const check = this.updateMinusValue(val, orderId, productId, stageId)
        if (check) {
          const qty = product.stages.find(s => s.id === stageId).value
          const apiUrl = process.env.VUE_APP_ENDPOINT_V1
         await axios.post(apiUrl + 'production/cancelstage/' + productId, {
            stageId: stageId,
            wid: jobId,
            value: qty,
            original: Number.parseInt(val)
          }).then(resp => {
            if (resp.data.status) {
              Swal.fire({
                title: 'Інформація!',
                text: resp.data.msg,
                icon: 'info',
                confirmButtonText: 'Ok'
              })
            } else {
              Swal.fire({
                title: 'Помилка!',
                text: resp.data.msg,
                icon: 'error',
                confirmButtonText: 'Ok'
              })
            }
          })
          this.fetchData()
        }
      } else {
        Swal.fire({
          title: 'Помилка!',
          text: 'Для того щоб закрити етап, вам потрібно відкрити зміну!',
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      }
    },
    updateMinusValue (val, orderId, productId, stageId) {
      const value = Number.parseInt(val)
      if (typeof value === 'number') {
        const order = this.items.find(o => o.id === orderId)
        const product = order.products.find(p => p.id === productId)
        const stage = product.stages.find(s => s.id === stageId)
        if (stage) {
          const calc = stage.ready_qty - value
          if (calc > product.quantity) {
            Swal.fire({
              title: 'Помилка!',
              text: 'Значення яке задається менше за к-сть товару для виготовлення!',
              icon: 'error',
              confirmButtonText: 'Ok'
            })
            stage.value = product.quantity
            this.$refs.tableTasks.refresh()
            return false
          } else if (calc < 0) {
            Swal.fire({
              title: 'Помилка!',
              text: 'Значення яке задається, не повинно бути меншим за загальну к-сть!',
              icon: 'error',
              confirmButtonText: 'Ok'
            })
            return false
          } else {
            const c = value === 0 ? 0 : stage.ready_qty - value
            stage.value = c
            return true
          }
        }
      } else {
        Swal.fire({
          title: 'Помилка!',
          text: 'Значення яке задається повинно бути числом!',
          icon: 'error',
          confirmButtonText: 'Ok'
        })
        return false
      }
    },
    async doneStage (orderId, productId, stageId, jobId, status) {
      if (this.shift) {
        const order = this.items.find(o => o.id === orderId)
        const product = order.products.find(p => p.id === productId)
        const stage = product.stages.find(s => s.id === stageId)
        const val = stage.value
        const check = this.updatePlusValue(val, orderId, productId, stageId)
        if (check) {
          const qty = product.stages.find(s => s.id === stageId).value
          const apiUrl = process.env.VUE_APP_ENDPOINT_V1
          await axios.post(apiUrl + 'production/donestage', {
            stageId: stageId,
            productId: jobId,
            process_id: this.processId,
            value: qty,
            original: Number.parseInt(val),
            status: status
          })
          this.fetchData()
        }
      } else {
        Swal.fire({
          title: 'Помилка!',
          text: 'Для того щоб закрити етап, вам потрібно відкрити зміну!',
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      }
    },
    updatePlusValue (val, orderId, productId, stageId) {
      const value = Number.parseInt(val)
      if (typeof value === 'number') {
        const order = this.items.find(o => o.id === orderId)
        const product = order.products.find(p => p.id === productId)
        const stage = product.stages.find(s => s.id === stageId)
        if (stage) {
          const calc = value + stage.ready_qty
          if (calc > product.quantity) {
            Swal.fire({
              title: 'Помилка!',
              text: 'Значення яке задається більше за к-сть товару для виготовлення!',
              icon: 'error',
              confirmButtonText: 'Ok'
            })
            stage.value = product.quantity
            this.$refs.tableTasks.refresh()
            return false
          } else if (value === 0) {
            Swal.fire({
              title: 'Помилка!',
              text: 'Значення не має бути рівним нулю!',
              icon: 'error',
              confirmButtonText: 'Ok'
            })
            stage.value = stage.ready_qty
            this.$refs.tableTasks.refresh()
            return false
          } else {
            stage.value = value + stage.ready_qty
            return true
          }
        }
      } else {
        Swal.fire({
          title: 'Помилка!',
          text: 'Значення яке задається повинно бути числом!',
          icon: 'error',
          confirmButtonText: 'Ok'
        })
        return false
      }
    },
    changeFilterDate (e) {
      const val = e.target.value
      this.urlParams.shift_date = val
      this.fetchData()
    },
    changeProcess (val) {
      // const val = this.selectedProcess
      this.urlParams.process_id = val
      this.fetchData()
      this.setProcessId(val)
    },
    selectAll () {},
    selectAllStages (e) {},
    selectStage (e, stageId, orderId, productId) {},
    showAllDetails () {
      this.currentItems.forEach((item) => {
        this.$set(item, '_showDetails', true)
      })
    },
    hideAllDetails () {
      this.currentItems.forEach((item) => {
        this.$set(item, '_showDetails', false)
      })
    },
    async fetchData () {
      const params = this.buildQuery() !== '' ? '&' + this.buildQuery() : ''
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      const { data } = await axios.get(
        apiUrl + 'production?page=' + this.currentPage + params
      )
      this.items = data.data
      this.currentPage = data.meta.pagination.current_page
      this.perPage = data.meta.pagination.per_page
      this.totalItems = data.meta.pagination.total
    },
    buildQuery () {
      return Object.entries(this.urlParams)
        .map((pair) => pair.map(encodeURIComponent).join('='))
        .join('&')
    },
    clearFilter () {
      this.urlParams = {}
      this.fetchData()
    }
  },
  watch: {
    currentPage: function (value) {
      this.fetchData().catch((error) => {
        console.log(error)
      })
    }
  }
}
</script>
<style>
.table {
  font-size: 0.9rem !important;
}
.table th,
.table td {
  padding: 0.3rem !important;
}
</style>
